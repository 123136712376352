import type { Ref, TextareaHTMLAttributes } from 'react'
import { forwardRef } from 'react'

import cn from 'classnames'

import { twMergeLDS } from '../../../../utils/twMergeLDS'

export type TextAreaFieldProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  placeholder: string
  label?: string
  helperText?: string
  isInvalid?: boolean
  isDisabled?: boolean
  isResizable?: boolean
  textAreaClassName?: string
  showLength?: boolean
}

export const TextAreaField = forwardRef(function TextAreaField(
  {
    className,
    placeholder,
    label,
    helperText,
    isInvalid = false,
    isDisabled = false,
    isResizable = false,
    textAreaClassName = '',
    maxLength,
    value,
    showLength = true,
    ...restProps
  }: TextAreaFieldProps,
  ref: Ref<HTMLTextAreaElement>
) {
  const isValid = !isInvalid
  const currentValueLength = typeof value === 'string' ? value.length : 0

  return (
    <div className={cn(twMergeLDS('grid grid-flow-row gap-y-[4px]', className))} data-allow-scroll>
      {label && <label className={cn('text-lds2-body3-regular text-text-secondary')}>{label}</label>}

      <textarea
        className={twMergeLDS(
          cn(
            'h-12 px-[16px] py-[14px]',
            'rounded border outline-none',
            'disabled:cursor-not-allowed disabled:border-border-mute disabled:bg-background-mute',
            {
              'enabled:border-border-secondary enabled:focus:border-border-brand [@media(pointer:fine)]:enabled:hover:border-border-brand':
                isValid,
              'enabled:border-border-error': isInvalid,
            },
            {
              'enabled:bg-background-primary': isValid,
              'enabled:bg-background-error-low': isInvalid,
            },
            {
              'resize-none': !isResizable,
            },
            'focus-visible:focused-ring',
            'text-lds2-body1-medium text-text-primary placeholder:text-text-mute',
            textAreaClassName
          )
        )}
        ref={ref}
        disabled={isDisabled}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        {...restProps}
      />

      <div className={cn('grid grid-flow-col', 'text-lds2-body3-regular', 'empty:hidden')}>
        {helperText && (
          <span
            className={cn('justify-self-start', {
              'text-text-secondary': isValid,
              'text-text-error': isInvalid,
            })}
          >
            {helperText}
          </span>
        )}
        {showLength && maxLength && (
          <span className={cn('justify-self-end')}>
            <span
              className={cn({
                'text-text-tertiary': isValid,
                'text-text-error': isInvalid,
              })}
            >
              {currentValueLength}
            </span>
            <span>/{maxLength}</span>
          </span>
        )}
      </div>
    </div>
  )
})
