import type { ReactNode, Ref } from 'react'
import { forwardRef } from 'react'

import { IconCheckCircle, IconInfo, IconWarning, IconX } from '@lbox/shared/components'
import type { StrictPropsWithChildren } from '@lbox/shared/types'

import cn from 'classnames'

import { twMergeLDS } from '../../../utils/twMergeLDS'
import type { ButtonProps, ButtonType } from '../button/Button'
import { Button } from '../button/Button'
import { IconOnlyButton } from '../button/IconOnlyButton'

type AlertType = 'warning' | 'error' | 'success' | 'info-high' | 'info-low'
export interface AlertProps {
  type: AlertType
  className?: string
  title?: ReactNode
  description?: ReactNode
  showCloseButton?: boolean
  filledButton?: ReactNode
  ghostButton?: ReactNode
  outlined?: boolean
  onClose?: VoidFunction
}

export const AlertComponent = forwardRef(function Alert(
  {
    type,
    className,
    title,
    description,
    showCloseButton,
    outlined = false,
    filledButton,
    ghostButton,
    onClose,
  }: AlertProps,
  forwardedRef: Ref<HTMLDivElement>
) {
  return (
    <div
      ref={forwardedRef}
      className={twMergeLDS(
        cn(
          'w-full',
          'relative flex gap-x-[12px] overflow-hidden rounded-[6px] p-[16px] pl-[48px]',
          'shadow-[0_0_0_1px_inset] shadow-transparent',
          {
            'bg-utility-yellow-lightest': type === 'warning',
            'bg-utility-rose-lightest': type === 'error',
            'bg-utility-green-lightest': type === 'success',
            'bg-utility-brand-lightest': type === 'info-high',
            'bg-utility-zinc-lightest': type === 'info-low',
          },
          {
            'shadow-utility-yellow-medium': outlined && type === 'warning',
            'shadow-utility-rose-medium': outlined && type === 'error',
            'shadow-utility-green-medium': outlined && type === 'success',
            'shadow-utility-brand-medium': outlined && type === 'info-high',
            'shadow-utility-zinc-medium': outlined && type === 'info-low',
          }
        ),
        className
      )}
    >
      <div
        className={cn(
          'absolute left-[16px] top-[16px]',
          'my-[2px]',
          'flex h-[20px] w-[20px] items-center justify-center'
        )}
      >
        {type === 'warning' && <IconWarning className={cn('fill-utility-yellow-dark')} size={20} weight="fill" />}
        {type === 'error' && <IconWarning className={cn('fill-utility-rose-dark')} size={20} weight="fill" />}
        {type === 'success' && <IconCheckCircle className={cn('fill-utility-green-dark')} size={20} weight="fill" />}
        {type === 'info-high' && <IconInfo className={cn('fill-utility-brand-dark')} size={20} weight="fill" />}
        {type === 'info-low' && <IconInfo className={cn('fill-utility-zinc-dark')} size={20} weight="fill" />}
      </div>

      <div className={cn('w-full', 'flex flex-col gap-[12px]')}>
        <div
          className={cn('relative flex flex-col gap-y-[8px]', {
            'pr-[24px]': showCloseButton,
          })}
        >
          {title && (
            <div
              className={cn('break-keep text-lds2-body2-medium', 'flex gap-x-[12px]', {
                'text-utility-yellow-darkest': type === 'warning',
                'text-utility-rose-dark': type === 'error',
                'text-utility-green-dark': type === 'success',
                'text-utility-brand-dark': type === 'info-high',
                'text-utility-zinc-dark': type === 'info-low',
              })}
            >
              <p className={cn('w-full', 'break-words break-keep')}>{title}</p>
            </div>
          )}

          {description && (
            <p
              className={cn('break-keep text-lds2-body3-regular', {
                'text-utility-yellow-darkest': type === 'warning',
                'text-utility-rose-dark': type === 'error',
                'text-utility-green-dark': type === 'success',
                'text-utility-brand-dark': type === 'info-high',
                'text-utility-zinc-dark': type === 'info-low',
              })}
            >
              {description}
            </p>
          )}

          {showCloseButton && (
            <div className={cn('absolute right-[-4px] top-[-4px]')}>
              <IconOnlyButton
                buttonType="ghost"
                size="small"
                icon={IconX}
                onClick={onClose}
                iconProps={{
                  className: cn({
                    'group-enabled:fill-utility-yellow-darkest': type === 'warning',
                    'group-enabled:fill-utility-rose-dark': type === 'error',
                    'group-enabled:fill-utility-green-dark': type === 'success',
                    'group-enabled:fill-utility-brand-dark': type === 'info-high',
                    'group-enabled:fill-utility-zinc-dark': type === 'info-low',
                  }),
                }}
              />
            </div>
          )}
        </div>

        {(filledButton || ghostButton) && (
          <ButtonGroup>
            {ghostButton}
            {filledButton}
          </ButtonGroup>
        )}
      </div>
    </div>
  )
})

const ButtonGroup = ({ children }: StrictPropsWithChildren) => {
  return <div className={cn('flex flex-col gap-[12px] lds2-tablet:flex-row')}>{children}</div>
}

export interface AlertButtonProps extends Omit<ButtonProps, 'buttonType' | 'size'> {
  buttonType: Extract<ButtonType, 'filled' | 'ghost'>
  type: AlertType
  className?: string
  onClick?: VoidFunction
}

const AlertButton = (props: AlertButtonProps) => {
  const { buttonType, type, label, className, ...rest } = props

  return (
    <Button
      type="button"
      buttonType={buttonType}
      size="small"
      label={label}
      className={twMergeLDS(
        cn('w-full lds2-tablet:w-fit', {
          'bg-transparent text-text-black': buttonType === 'ghost',

          'bg-utility-yellow-dark text-text-black': buttonType === 'filled' && type === 'warning',
          'bg-utility-rose-dark text-text-white': buttonType === 'filled' && type === 'error',
          'bg-utility-green-dark text-text-white': buttonType === 'filled' && type === 'success',
          'bg-utility-brand-dark text-text-white': buttonType === 'filled' && type === 'info-high',
          'bg-background-primary-inverse text-text-primary-inverse': buttonType === 'filled' && type === 'info-low',
        }),
        className
      )}
      {...rest}
    />
  )
}

/** @description : 각 Alert 별로 버튼 역시 고정되어야함 (AlertWarningComponent 에서는 AlertWarningButton 을 사용하도록) */
const Warning = forwardRef<HTMLDivElement, Omit<AlertProps, 'type'>>(function Warning(props, forwardedRef) {
  return <AlertComponent type="warning" ref={forwardedRef} {...props} />
})

const Error = forwardRef<HTMLDivElement, Omit<AlertProps, 'type'>>(function Error(props, forwardedRef) {
  return <AlertComponent type="error" ref={forwardedRef} {...props} />
})

const Success = forwardRef<HTMLDivElement, Omit<AlertProps, 'type'>>(function Success(props, forwardedRef) {
  return <AlertComponent type="success" ref={forwardedRef} {...props} />
})

const InfoHigh = forwardRef<HTMLDivElement, Omit<AlertProps, 'type'>>(function InfoHigh(props, forwardedRef) {
  return <AlertComponent type="info-high" ref={forwardedRef} {...props} />
})

const InfoLow = forwardRef<HTMLDivElement, Omit<AlertProps, 'type'>>(function InfoLow(props, forwardedRef) {
  return <AlertComponent type="info-low" ref={forwardedRef} {...props} />
})

/** @description : 타입 별 스타일이 적용되어 있지 않은 기본 버튼 역시 모듈로 내보냄 (다양한 사용을 고려) */
export const Alert = Object.assign(AlertComponent, {
  Warning: Object.assign(Warning, {
    Button: (props: Omit<AlertButtonProps, 'type'>) => <AlertButton type="warning" {...props} />,
  }),
  Error: Object.assign(Error, {
    Button: (props: Omit<AlertButtonProps, 'type'>) => <AlertButton type="error" {...props} />,
  }),
  Success: Object.assign(Success, {
    Button: (props: Omit<AlertButtonProps, 'type'>) => <AlertButton type="success" {...props} />,
  }),
  InfoHigh: Object.assign(InfoHigh, {
    Button: (props: Omit<AlertButtonProps, 'type'>) => <AlertButton type="info-high" {...props} />,
  }),
  InfoLow: Object.assign(InfoLow, {
    Button: (props: Omit<AlertButtonProps, 'type'>) => <AlertButton type="info-low" {...props} />,
  }),
})
