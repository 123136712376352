// 프로모션 종료 후(2025-09-30 23:59:59), 제거될 예정입니다.

import { useSearchParams } from 'next/navigation'

import { merge } from '@lbox/shared/utils'

import { useIsMutating, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import useMe from '../../hooks/useMe'
import { lboxHttpClient } from '../../utils/api'

type AiBoostingTargetUserResponse = {
  status: number
  code: string
  data: {
    userId: number
    participated: boolean
    excluded: boolean
    totalPoint: number
    monthlyPoint: number
    nextPoint: number
    markedDates: string[]
  } | null
}

export function useGetTargetUser() {
  const searchParams = useSearchParams()

  const { isLoggedIn } = useMe()

  // 테스트 용도로 사용하는 쿼리 파라미터
  const targetDate = searchParams?.get('targetDate')

  return useQuery<AiBoostingTargetUserResponse>({
    queryKey: ['promotions', 'ai-boosting', 'target-user'],
    queryFn: () =>
      lboxHttpClient
        .get('/promotions/ai-boosting/target-user', {
          params: targetDate ? { targetDate, seeOutOfPromotion: true } : undefined,
        })
        .then((res) => res.data),
    refetchOnMount: true,
    retry: false,
    enabled: isLoggedIn,
  })
}

export function useParticipateAiBoosting() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () =>
      lboxHttpClient.post('/promotions/ai-boosting/target-user/participate', {
        promotionCode: 'AI_BOOSTING',
        promotionType: 'POINT_REWARD',
        memo: null,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['promotions', 'ai-boosting'] })

      // optimistic update
      queryClient.setQueryData(
        ['promotions', 'ai-boosting', 'target-user'],
        (data?: AiBoostingTargetUserResponse) =>
          data &&
          merge(data, {
            data: { participated: true },
          })
      )
    },
    mutationKey: ['participateAiBoosting'],
  })
}

export function useIsParticipating() {
  return useIsMutating({ mutationKey: ['participateAiBoosting'] }) > 0
}
