import { isServer } from '@toss/utils'

import { getOldClientHostnameByEnvironment } from './getClientHostName'

/**
 * 개발 환경에 맞는 URL authority 반환
 * - https://developer.mozilla.org/en-US/docs/Learn/Common_questions/Web_mechanics/What_is_a_URL#authority
 */
export function getAuthority(): string {
  if (isServer()) {
    return getOldClientHostnameByEnvironment()
  }

  if (window.location.host === 'localhost:3000') {
    return 'http://localhost:4200'
  } else {
    return window.location.origin
  }
}
