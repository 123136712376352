import { IconX, Portal } from '@lbox/shared/components'

import { IconOnlyButton } from '@lbox-kr/libra/v2'
import { AnimatePresence, motion } from 'framer-motion'

export default function ChallengeDrawerTemplate({
  children,
  onClose,
  isOpen,
  onCloseComplete,
}: {
  children: React.ReactNode
  onClose?: VoidFunction
  onCloseComplete?: VoidFunction
  isOpen: boolean
}) {
  return (
    <AnimatePresence onExitComplete={onCloseComplete}>
      {isOpen && (
        <Portal rootId="drawer-root" isOpen>
          <motion.aside
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ ease: 'linear' }}
            className="fixed right-0 top-0 z-50 h-full w-full overflow-y-auto bg-[#E8EBEE] shadow-2xl lds2-tablet:max-w-[500px]"
          >
            <div className="h-full max-h-[900px] bg-[linear-gradient(180deg,#F8F8F8_0%,#DEE6EC_34%,#E8EBEE_76%)]">
              <header className="flex items-center justify-between px-6 py-4">
                <h2 className="text-lds2-heading4-semibold text-text-primary">AI 꾸준히 챌린지</h2>
                <IconOnlyButton buttonType="ghost" icon={IconX} onClick={onClose} />
              </header>
              <div className="relative">{children}</div>
            </div>
          </motion.aside>
        </Portal>
      )}
    </AnimatePresence>
  )
}
