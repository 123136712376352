import { useEffect, useState } from 'react'

import { useWindowResize, useWindowScroll, useOnClickOutside } from '@lbox/shared/hooks'

import { useIsomorphicLayoutEffect, useIsMounted } from '@toss/react'

import type { PopoverProps } from './Popover'

type usePopoverParams = Pick<
  PopoverProps,
  'isOpen' | 'renderTarget' | 'justify' | 'onClickBackdrop' | 'onClickOutsidePopover'
>

export const usePopover = ({
  isOpen,
  renderTarget,
  justify,
  onClickBackdrop,
  onClickOutsidePopover,
}: usePopoverParams) => {
  const isMounted = useIsMounted()

  const [finalPosition, setFinalPosition] = useState({
    top: 0,
    left: 0,
  })
  const popoverRef = useOnClickOutside<HTMLDivElement>(() => {
    onClickOutsidePopover?.()
  })

  useWindowResize({ onResize: adjustPosition })
  useWindowScroll({ onScroll: adjustPosition })

  function handleClickBackdrop($event: React.MouseEvent) {
    const clickedElement = $event.target as HTMLDivElement
    const popoverElement = popoverRef.current

    /** 클릭한 요소가 Popover 컴포넌트에 속하지 않아야 Backdrop 을 클릭했다고 판단함 */
    if (!popoverElement?.contains(clickedElement)) {
      onClickBackdrop?.()
    }
  }

  function adjustPosition() {
    const popover = popoverRef.current
    const renderTargetRect = renderTarget?.getBoundingClientRect()

    if (!renderTargetRect || !popover) {
      return
    }

    // 상하 위치 조정 (window를 벗어나서 잘리는 부분이 없도록)
    const {
      top: targetTopPosition,
      left: targetElemLeftPosition,
      width: targetWidth,
      height: targetHeight,
    } = renderTargetRect

    const { width: popoverWidth, height: popoverHeight } = popover.getBoundingClientRect()
    const widthDiff = targetWidth - popoverWidth
    const leftPosition = justify === 'left' ? targetElemLeftPosition : targetElemLeftPosition + widthDiff

    /** window 하단을 벗어나는 경우 */
    if (targetTopPosition + popoverHeight > window.innerHeight) {
      const overflowedAmountY = targetTopPosition + popoverHeight - window.innerHeight
      const marginFromBottom = 20

      setFinalPosition({
        top: targetTopPosition + targetHeight - overflowedAmountY - marginFromBottom,
        left: leftPosition,
      })
    } else {
      setFinalPosition({
        top: targetTopPosition + targetHeight,
        left: leftPosition,
      })
    }
  }

  useIsomorphicLayoutEffect(() => {
    if (isMounted && isOpen) {
      adjustPosition()
    }
  }, [isMounted, isOpen])

  useEffect(() => {
    if (!renderTarget) {
      return
    }

    const observer = new ResizeObserver((entries) => {
      if (!entries[0]) {
        return
      }

      adjustPosition()
    })

    observer.observe(renderTarget)

    return () => {
      observer.unobserve(renderTarget)
    }
    /** @description renderTarget이 null -> HTMLElement 가 될때만 사이드 이펙트 실행됨. HTMLElement 간 참조 값의 변화는 없어 트리거 되지 않음. */
  }, [renderTarget])

  return { finalPosition, popoverRef, handleClickBackdrop }
}
