import type { Ref } from 'react'
import { forwardRef } from 'react'

import { IconEye, IconEyeSlash, IconXCircle } from '@lbox/shared/components'
import { generateUUID, noop } from '@lbox/shared/utils'

import { useBooleanState } from '@toss/react'
import cn from 'classnames'

import type {
  EmailInputFieldProps,
  InputFieldProps,
  NumberInputFieldProps,
  PasswordInputFieldProps,
  TextInputFieldProps,
} from './$types/types'
import { twMergeLDS } from '../../../../utils/twMergeLDS'

const InputFieldComponent = forwardRef(function InputField(props: InputFieldProps, ref: Ref<HTMLInputElement>) {
  const {
    label,
    type,
    helperText,
    isInvalid,
    isDisabled: isDisabledProp,
    disabled,
    containerClassName,
    labelClassName,
    inputClassName,
    inputContainerClassName,
    leftIcon,
    leftIconContainerClassName,
    rightIcon,
    rightIconContainerClassName,
    inputSize = 'medium',
    bgColor = 'white',
    ...restProps
  } = props
  const isDisabled = isDisabledProp || disabled
  const [isValid, isEnabled] = [!isInvalid, !isDisabled]

  const inputElementId = typeof label === 'string' ? label : `input-id-${generateUUID()}`

  return (
    <div className={twMergeLDS(cn('flex flex-col gap-y-1', containerClassName))}>
      {label && (
        <label
          htmlFor={inputElementId}
          className={twMergeLDS(cn('text-start text-lds2-body3-regular text-text-secondary'), labelClassName)}
        >
          {label}
        </label>
      )}

      <label
        htmlFor={inputElementId}
        className={twMergeLDS(
          cn(
            'flex gap-[8px]',
            {
              'h-[32px] px-[7px] py-[3px]': inputSize === 'xsmall',
              'h-[40px] px-[16px] py-[8px]': inputSize === 'small',
              'h-[48px] px-[16px] py-[12px]': inputSize === 'medium',
            },
            'w-full',
            'rounded',
            {
              'border border-border-primary focus-within:focused-ring [@media(pointer:fine)]:hover:border-border-brand':
                isEnabled && isValid,
              'border border-border-error': isEnabled && isInvalid,
            },
            {
              'bg-background-primary': isEnabled && isValid && bgColor === 'white',
              'bg-background-secondary': isEnabled && isValid && bgColor === 'grey',
              'bg-background-error-low': isEnabled && isInvalid,
            },
            {
              'bg-background-mute': isDisabled,
            }
          ),
          inputContainerClassName
        )}
      >
        {leftIcon && (
          <div className={twMergeLDS(cn('flex items-center justify-center'), leftIconContainerClassName)}>
            {leftIcon}
          </div>
        )}

        <input
          id={inputElementId}
          className={twMergeLDS(
            cn(
              'w-full',
              'text-text-primary placeholder:text-text-mute',
              'outline-none',
              /**
               * @description
               * angular에서 디자인 무너지는 현상 때문에 추가
               */
              'border-none p-0',
              'bg-transparent',
              /**
               * @description
               * 특정 사파리 버전에서 input color가 보이지 않는 문제가 있어 해당 값은 custom className 설정에서 변경해 줘야함.
               */
              'disabled:input-disabled',
              {
                'text-lds2-body1-medium-trimmed': inputSize === 'small' || inputSize === 'medium',
                'text-lds2-body2-medium-trimmed': inputSize === 'xsmall',
              }
            ),
            inputClassName
          )}
          ref={ref}
          type={type}
          disabled={isDisabled}
          {...restProps}
        />

        {rightIcon && (
          <div className={twMergeLDS(cn('flex items-center justify-center'), rightIconContainerClassName)}>
            {rightIcon}
          </div>
        )}
      </label>

      {helperText && (
        <span
          className={cn('text-lds2-body3-regular', {
            'text-text-secondary': isValid,
            'text-text-error': isInvalid,
          })}
        >
          {helperText}
        </span>
      )}
    </div>
  )
})

const TextInputField = forwardRef(function TextInputField(props: TextInputFieldProps, ref: Ref<HTMLInputElement>) {
  return <InputFieldComponent {...props} ref={ref} type="text" />
})

const NumberInputField = forwardRef(function NumberInputField(
  props: NumberInputFieldProps,
  ref: Ref<HTMLInputElement>
) {
  return <InputFieldComponent {...props} ref={ref} type="number" />
})

const EmailInputField = forwardRef(function EmailInputField(props: EmailInputFieldProps, ref: Ref<HTMLInputElement>) {
  return <InputFieldComponent {...props} ref={ref} type="email" />
})

const PasswordInputField = forwardRef(function PasswordInputField(
  props: PasswordInputFieldProps,
  ref: Ref<HTMLInputElement>
) {
  const { onClickXCircleIcon = noop, onClickEyeIcon = noop } = props

  const [isShowPassword, , , toggleIsShowPassword] = useBooleanState(false)

  const handleClickXCircleIcon = () => {
    onClickXCircleIcon()
  }

  const handleClickEyeIcon = () => {
    toggleIsShowPassword()

    onClickEyeIcon()
  }

  return (
    <InputFieldComponent
      {...props}
      ref={ref}
      type={isShowPassword ? 'text' : 'password'}
      rightIcon={
        <>
          <button className={cn('text-foreground-secondary')} onClick={handleClickXCircleIcon} type="button">
            <IconXCircle size={24} weight="fill" />
          </button>

          <button className={cn('text-foreground-secondary')} onClick={handleClickEyeIcon} type="button">
            {isShowPassword ? <IconEye size={24} weight="fill" /> : <IconEyeSlash size={24} weight="fill" />}
          </button>
        </>
      }
      rightIconContainerClassName={cn('flex gap-x-[8px]')}
    />
  )
})

export const InputField = {
  Base: InputFieldComponent,
  Text: TextInputField,
  Password: PasswordInputField,
  Number: NumberInputField,
  Email: EmailInputField,
}
