export const V1_PAGE_PATH = {
  bookmark: {
    index: '/bookmark',
    history: '/bookmark/history',
  },
  casemapWrapper: {
    index: '/casemapwrapper',
  },
  commentary: {
    index: '/commentary',
  },
  reference: {
    dbpia: {
      index: '/reference/dbpia',
    },
  },
  search: {
    case: {
      index: '/search/case',
    },
  },
  case: {
    index: '/case',
    withDocId: ({ court, caseNumber }: { court: string; caseNumber: string }) => `/case/${court}/${caseNumber}`,
  },
  substitute: {
    preview: {
      index: '/substitute/preview',
    },
  },
  user: {
    myPage: {
      info: '/user/myPage/info',
    },
    calendar: {
      main: {
        index: '/user/calendar/main',
      },
    },
    nonProfit: {
      landing: {
        index: '/user/non-profit/landing',
      },
    },
    profile: {
      doCases: {
        index: '/user/profile/doCases',
      },
    },
  },
  rule: {
    index: '/rule',
  },
} as const
