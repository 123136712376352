import type { ReactNode } from 'react'

import { Portal } from '@lbox/shared/components'
import { noop } from '@lbox/shared/utils'

import cn from 'classnames'
import type { AnimationProps } from 'framer-motion'
import { AnimatePresence, motion } from 'framer-motion'

import { usePopover } from './usePopover'
import { twMergeLDS } from '../../../utils/twMergeLDS'

export interface PopoverProps {
  /** Popover 열림 여부 */
  isOpen: boolean
  /** Popover 상단에 들어갈 UI */
  top?: ReactNode
  /** Popover 본문에 들어갈 UI */
  body?: ReactNode
  /** Popover 하단에 들어갈 UI */
  bottom?: ReactNode
  /** Popover 위치를 잡을 떄 사용될 기준 element */
  renderTarget?: HTMLElement | null
  /** 전체 화면을 뒤덮는 backdrop UI 사용 여부 */
  usesFullScreenBackdrop?: boolean
  /** 수평 끝 정렬 기준 */
  justify?: 'left' | 'right'
  /** popover 요소에 적용할 className */
  rootClassName?: string
  /** top 요소에 적용할 className */
  topClassName?: string
  /** backdrop 요소에 적용할 className */
  backdropClassName?: string
  /** body 요소에 적용할 className */
  bodyClassName?: string
  /** bottom 요소에 적용할 className */
  bottomClassName?: string
  /** framer-motion animation 객체 */
  framerMotionAnimationObject?: AnimationProps
  /** Popover backdrop 클릭 시 실행할 콜백 함수 */
  onClickBackdrop?: VoidFunction
  /** Popover 요소 바깥 클릭 시 실행할 콜백 함수 */
  onClickOutsidePopover?: VoidFunction
}

export const Popover = ({
  isOpen = false,
  top,
  body,
  bottom,
  renderTarget,
  usesFullScreenBackdrop = true,
  justify = 'left',
  backdropClassName = '',
  rootClassName = '',
  topClassName = '',
  bodyClassName = '',
  bottomClassName = '',
  framerMotionAnimationObject = {},
  onClickBackdrop = noop,
  onClickOutsidePopover = noop,
}: PopoverProps) => {
  const { finalPosition, popoverRef, handleClickBackdrop } = usePopover({
    isOpen,
    renderTarget,
    justify,
    onClickBackdrop,
    onClickOutsidePopover,
  })

  return (
    <Portal isOpen={isOpen} rootId="popover-root" forceMount>
      <AnimatePresence>
        {/* Backdrop */}
        {isOpen && (
          <div
            className={twMergeLDS(cn('fixed', { 'inset-0': usesFullScreenBackdrop }, backdropClassName))}
            onClick={handleClickBackdrop}
          />
        )}
        {/* Popover */}
        {isOpen && (
          <motion.div
            key="popover-wrapper"
            ref={popoverRef}
            style={{
              position: 'fixed',
              top: finalPosition.top,
              left: finalPosition.left,
            }}
            className={twMergeLDS(
              'bg-white',
              'rounded-[4px] border-[0.5px] border-zinc-200',
              'shadow-[0px_4px_6px_-2px_rgba(0,0,0,0.05),_0px_10px_15px_-3px_rgba(0,0,0,0.10)]',
              rootClassName
            )}
            {...framerMotionAnimationObject}
          >
            {top && <div className={twMergeLDS('p-[16px]', topClassName)}>{top}</div>}
            {body && <div className={twMergeLDS('px-[8px]', bodyClassName)}>{body}</div>}
            {bottom && <div className={twMergeLDS('p-[16px]', bottomClassName)}>{bottom}</div>}
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  )
}
