import type { Ref } from 'react'
import { forwardRef } from 'react'

import Simplebar from 'simplebar-react'
import type { Props as SimplebarProps } from 'simplebar-react'

import './simplebarStyles.css'

export type CustomScrollBarProps = SimplebarProps

/**
 * LDS Custom Scrollbar
 */
export const CustomScrollBar = forwardRef(function _(props: CustomScrollBarProps, ref: Ref<HTMLDivElement>) {
  return (
    <Simplebar
      {...props}
      scrollableNodeProps={{
        ref,
      }}
    />
  )
})
