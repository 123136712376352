import { useEffect, useRef } from 'react'

import { usePreservedCallback } from '@toss/react'

export interface UseWindowScrollParams {
  onScroll: VoidFunction
}

/**
 * window scroll 이벤트를 감지하여 인자로 주어진 callback 함수를 실행한다
 */
export const useWindowScroll = ({ onScroll }: UseWindowScrollParams) => {
  const isQueuedRef = useRef(false)
  const onScrollCallback = usePreservedCallback(onScroll)

  useEffect(() => {
    isQueuedRef.current = false

    const handleScroll = () => {
      if (isQueuedRef.current) {
        return
      }

      isQueuedRef.current = true

      window.requestAnimationFrame(() => {
        onScrollCallback()
        isQueuedRef.current = false
      })
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [onScrollCallback])
}
