import type { InputHTMLAttributes, ReactNode, Ref } from 'react'
import { forwardRef } from 'react'

import cn from 'classnames'

import styles from './styles.module.css'
import { twMergeLDS } from '../../../utils'

type CheckboxNativeAttributes = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>
type CheckboxCustomProps = {
  label?: ReactNode
  labelClassName?: string
}

export type CheckboxProps = CheckboxNativeAttributes & CheckboxCustomProps

export const Checkbox = forwardRef(function Checkbox(
  { className, labelClassName = '', checked, defaultChecked, label, ...rest }: CheckboxProps,
  forwardedRef: Ref<HTMLInputElement>
) {
  return (
    <label
      className={twMergeLDS(
        cn('inline-flex cursor-pointer', `${labelClassName}`, {
          'font-bold': checked,
        })
      )}
    >
      <input
        className={cn(
          'relative inline-block h-[24px] w-[24px] cursor-pointer appearance-none',
          'transition-[background-color,border-color] duration-300 ease-out',
          /** @desc: [Enabled] background color */
          'rounded-[4px] bg-background-elevation-level1 outline-0 enabled:checked:bg-background-brand',
          /** @desc: [Enabled] box border color */
          'border enabled:border-border-primary enabled:checked:border-background-brand [@media(pointer:fine)]:enabled:hover:border-border-primary [@media(pointer:fine)]:enabled:hover:checked:border-background-brand',
          /** @desc: [Disabled] background, box border color */
          'disabled:cursor-not-allowed disabled:border-border-mute disabled:bg-background-mute disabled:checked:border-transparent disabled:checked:bg-background-brand-mute',
          /** @desc: focused ring */
          'focus-visible:focused-double-ring',
          'before:absolute before:left-0 before:top-0 before:h-[24px] before:w-[24px] before:rounded-[4px]',
          'before:transition-[background-color] before:duration-300 before:ease-out',
          /** @desc: [Enabled] hover interaction state */
          'before:bg-transparent [@media(pointer:fine)]:enabled:hover:before:bg-state-hovered-primary [@media(pointer:fine)]:enabled:checked:hover:before:bg-state-hovered-on-brand',
          'after:absolute after:left-[1px] after:top-[1px] after:h-[20px] after:w-[20px]',
          'checked:after:block [&:not(:checked)]:after:hidden',
          {
            'mr-[0.5rem]': label,
          },
          styles.checkbox,
          className
        )}
        type="checkbox"
        defaultChecked={defaultChecked}
        checked={checked}
        ref={forwardedRef}
        {...rest}
      />
      {label}
    </label>
  )
})
