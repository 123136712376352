import { useEffect, useRef } from 'react'

/**
 * 지정된 ref DOM 요소 바깥에서 발생한 클릭 이벤트를 감지하여
 * 핸들러를 실행해주는 hook
 */
export const useOnClickOutside = <T extends HTMLElement>(handler: ($event: MouseEvent | TouchEvent) => unknown) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    const listener = ($event: MouseEvent | TouchEvent) => {
      if (!ref.current) {
        return
      }

      const clickTargetElem = $event.target
      // 자기 자신을 클릭했거나, 자식 요소들을 클릭 했을 경우 아무 것도 하지 않음.
      if (clickTargetElem instanceof Element && ref.current.contains(clickTargetElem)) {
        return
      }

      handler($event)
    }

    document.addEventListener('mouseup', listener)
    document.addEventListener('touchend', listener)

    return () => {
      document.removeEventListener('mouseup', listener)
      document.removeEventListener('touchend', listener)
    }
  }, [ref, handler])

  return ref
}
