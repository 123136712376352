/**
 * Application 실행 환경에 따라 legacy client 의 hostname 을 반환한다
 *
 * 환경 변수를 활용하므로 server-side 에서도 문제없이 동작한다
 * 해당 환경 변수는 package.json script 에서 주입된다
 */
export const getOldClientHostnameByEnvironment = () => {
  switch (process.env.NEXT_PUBLIC_ENV) {
    case 'dev':
      return 'https://dev.lbox.kr'
    case 'staging':
      return 'https://stg.lbox.kr'
    case 'production':
      return 'https://lbox.kr'
    default:
      return 'http://localhost:4200'
  }
}
