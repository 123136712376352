import type { Ref } from 'react'
import { useId, forwardRef } from 'react'

import cn from 'classnames'

interface AvatarBaseProps {
  /** 픽셀 단위의 크기 */
  size: number
}

interface ImageAvatarProps extends AvatarBaseProps {
  /** Avatar 종류 image | placeholder | initial */
  type: 'image'
  /** 아바타 이미지 url */
  src: string
  /** 아바타 이미지의 대체 텍스트 */
  alt?: string
}

interface PlaceholderAvatarProps extends AvatarBaseProps {
  /** Avatar 종류 image | placeholder | initial */
  type: 'placeholder'
}

interface InitialAvatarProps extends AvatarBaseProps {
  /** Avatar 종류 image | placeholder | initial */
  type: 'initial'
  /** 유저의 이니셜  */
  initial: string
}

export type AvatarProps = ImageAvatarProps | PlaceholderAvatarProps | InitialAvatarProps

export const Avatar = forwardRef(function Avatar(props: AvatarProps, forwardedRef: Ref<HTMLElement>) {
  const { size, type } = props

  const maskUUID = useId()

  return (
    <figure
      className={cn(`inline-block overflow-hidden rounded-[50%]`)}
      style={{ width: `${size}px`, height: `${size}px` }}
      ref={forwardedRef}
    >
      {type === 'image' && <img src={props.src} alt={props.alt} className={cn('block h-full w-full object-cover')} />}

      {type === 'placeholder' && <PlaceholderIcon size={size} maskId={maskUUID} />}

      {type === 'initial' && (
        <figcaption
          className={cn('flex h-full w-full items-center justify-center', 'bg-zinc-500 text-white', {
            'text-lds2-caption2-medium': size < 32,
            'text-lds2-body3-medium': 32 <= size && size < 40,
            'text-lds2-body2-medium': 40 <= size && size < 48,
            'text-lds2-body1-medium': 48 <= size && size < 56,
            'text-lds2-heading4-medium': 56 <= size && size < 64,
            'text-lds2-heading3-medium': 64 <= size,
          })}
        >
          {props.initial.slice(0, 2)}
        </figcaption>
      )}
    </figure>
  )
})

function PlaceholderIcon({ size, maskId }: { size: number; maskId: string }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#F3F4F6" />
      <mask id={`mask-${maskId}`} maskUnits="userSpaceOnUse" x="3" y="4" width="18" height="28">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 12.625C14.2782 12.625 16.125 10.7782 16.125 8.5C16.125 6.22183 14.2782 4.375 12 4.375C9.72183 4.375 7.875 6.22183 7.875 8.5C7.875 10.7782 9.72183 12.625 12 12.625ZM12 31.75C16.9706 31.75 21 27.7206 21 22.75C21 17.7794 16.9706 13.75 12 13.75C7.02944 13.75 3 17.7794 3 22.75C3 27.7206 7.02944 31.75 12 31.75Z"
          fill="#D1D5DB"
        />
      </mask>
      <g mask={`url(#mask-${maskId})`}>
        <circle cx="12" cy="12" r="12" fill="#D1D5DB" />
      </g>
    </svg>
  )
}
