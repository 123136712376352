import ChallengeDrawerTemplate from './ChallengeDrawerTemplate'
import StatusChallengeContent from './StatusChallengeContent'

export default function StatusChallengeDrawer({
  onClose,
  isOpen,
  onCloseComplete,
}: {
  onClose?: VoidFunction
  isOpen: boolean
  onCloseComplete?: VoidFunction
}) {
  return (
    <ChallengeDrawerTemplate onClose={onClose} isOpen={isOpen} onCloseComplete={onCloseComplete}>
      <StatusChallengeContent onClose={onClose} />
    </ChallengeDrawerTemplate>
  )
}
